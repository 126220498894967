
.home{
    display:flex;
    flex-direction: column;
    width:100%;
    /* min-height: fit-content; */
    height:100vh;
    /* background: url('/public/images/living1.jpg') no-repeat;
    background-size: 100%; */
    /* z-index: 20; */
    /* border:1px solid black; */
    

}
.home_img_con{
    display:flex;
    width:100%;
    height:fit-content;
}
.home_img_con_img {
    width: 100%;
    height: 100vh;
    position: absolute;
    left:0;
    top:0;
    z-index: -10;
    margin-top: auto;
    margin-bottom: auto;
    /* background-image: url("/public/images/dishoek.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
}
.home_banner{
    display:flex;
    height:fit-content;
    width:80%;
    margin-left: auto;
    margin-right: auto;
    border:1px solid #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    place-content: center;
    font-size: 140px;
    color:#ffffff;
    /* margin-bottom: 100px; */
    /* font-family: Garet Book; */
}
@media only screen and (max-width: 1600px) {
    .home_banner{
        font-size: 140px;
    }
}
@media only screen and (max-width: 1200px) {
    .home_banner{
        font-size: 115px;
    }
}
@media only screen and (max-width: 992px) {
    .home_banner{
        font-size: 90px;
    }
}
@media only screen and (max-width: 768px) {
    .home_banner{
        font-size: 80px;
    }
    .home_img_con_img {
      
        background-image: url("/public/images/dishoekMob.jpg");
        
    }

}
@media only screen and (max-width: 576px) {
    .home_banner{
        font-size: 65px;
    }
}
@media only screen and (max-width: 480px) {
    .home_banner{
        font-size: 50px;
    }
}