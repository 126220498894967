/* @import url(//db.onlinewebfonts.com/c/345a4b9dc453432314209d38245e4bff?family=SVN-Miller+Banner); */
.subItem{
    display:flex;
    width:100%;
    height:fit-content;
    margin-top: 75px;
    margin-bottom: 75px;
    /* font-family:SVN-Miller Banner; */
}
.subItem_con {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    
    height: 100%;
    margin-top: 50px;
    /* border: 1px solid black; */
}

.subItem_imgCon{
    display:flex;
    flex-direction: column;
    width:100%;
    
    
    /* height: fit-content;
    max-height:60%; */
    height:60%;
    /* border: 1px solid black; */

}
.subItem_img {
    display: flex;
    width: auto;
        height: 80vh;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        
}
.subItem_buttons{
    display:flex;
    width:fit-content;
    margin-left: auto;
    margin-right: auto;
margin-top: 10px;
}
.subItem_icon{
    display:flex;
    color:grey;
    margin-left: 5px;
    margin-right: 5px;
}
.subItem_icon:hover {
cursor: pointer;
}
.subItem_textCon{
    display:flex;
    flex-direction: column;
     width:33%; 
        margin-right: auto;
        height:fit-content;
        font-family: sans-serif;
       
}
.subItem_title{
    display:flex;
    font-size: 30px;
    font-weight: bold;
    margin-right: auto;
    font-family:SVN-Miller Banner;
    
}
.subItem_desc {
    display: flex;
    font-size: 22px;
    font-weight: 500;
    margin-top: 30px;
    color: #000;
    text-align: justify;
   
    
}
.subItem_bottom{
    display: flex;
        
        width: 100%;
        height:40%;
        margin-top: 50px;
        
        

}
.subItem_bottom_moreCon{
    display:flex;
    width:67%;
    height:100%;
    /* border:1px solid red; */
   
}
.subItem_bottom_more{
    display:flex;
    width:auto;
    height:150px;
    margin-top: 6%;
    margin-left: 25%;
    /* border: 1px solid black; */
}
.subItem_bottom_more:hover {
cursor: pointer;
scale:1.2;
transition-delay: 120ms;
}
.subItem_map{
    
height:500px;

width:100%;
}

.subItem_links{
    display:flex;
   
    /* margin-right: auto; */
    width: 60%;
}
.subItem_links_con{
    display: flex;
        flex-direction: column;
        width:100%;
        height: 70%;
            margin-top: auto;
            margin-bottom: auto;

}
.subItem_links_elm{
    display:flex;
    width:100%;

    height:50px;
    margin-left: auto;
    margin-right: auto;
   
    font-size: 40px ;
   
    align-items: center;
    place-content: center;


    
}
.subItem_links_img{
    display: flex;
    
    width: fit-content;
    /* margin-top: auto;
    margin-bottom: auto; */
    margin-right: 6px;
   

}
.subItem_links_text{
display:flex;
width:100%;
font-size: 16px;
font-weight: 700;
/* margin-top: auto;
    margin-bottom: auto; */
    align-items: center;
    margin-right: auto;
    text-decoration: underline;
   
}
.subItem_links_text:hover{
cursor: pointer;
}


@media only screen and (max-width: 1600px) {
    .subItem_con {
        width: 80%;


    }
    .subItem_title {
        font-size: 25px;

    }
    .subItem_desc {
        font-size: 20px;
       

    }
   
       
}
@media only screen and (max-width: 1200px) {

    .subItem_title {
        font-size: 20px;
    }
    .subItem_desc {
        font-size: 18px;

    }
    .subItem_con {
        width: 90%;
    }
   
       
}
@media only screen and (max-width: 992px) {
    .subItem_title {
        font-size: 18px;
    }
    .subItem_desc {
        font-size: 16px;

    }
    .subItem_con {
        width: 95%;
    }
    .subItem_links_text{
        font-size: 16px;
    }
   
       
}
@media only screen and (max-width: 768px) {
    .subItem_title {
        font-size: 17px;
    }
    .subItem_desc {
        font-size: 15px;

    }
    .subItem_con {
        width: 97%;
    }
    .subItem_img {
        width: 100%;
            height: auto;
           
    }
    .subItem{
       
        height:100%;
       
    }
    .subItem_links_text{
        font-size: 15px;
    }
   
       
}
@media only screen and (max-width: 576px) {
    .subItem_title {
        font-size: 16px;
    }
    .subItem_desc {
        font-size: 14px;

    }
    .subItem_links_text{
        font-size: 14px;
    }

   
       
}
@media only screen and (max-width: 480px) {
    .subItem_title {
        font-size: 15px;
    }
    .subItem_desc {
        font-size: 13px;

    }
    .subItem_links_text{
        font-size: 13px;
    }
   
   
       
}