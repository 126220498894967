.bet{
    display:flex;
    flex-direction: column;
    margin-top: 10%;
    width:70%;
    margin:auto auto;
    /* border:1px solid black; */
    margin-top: 50px;
    
}
.bet_btn{
    display:flex;
    margin-left:auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding:20px;
    border-radius: 20px;
    background-color: aqua;
    font-size: 15px;
    font-weight: bold;
}