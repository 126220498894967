.acc{
    display:flex;
    flex-direction: column;
    width:100%;
    height:100vh;
   
}
.acc_grid{
    display:flex;
    width:50%;
    margin-left:auto;
    margin-right: auto;
    margin-bottom: 50px;
}
.acc_grid_elm{
    display:flex;
    width:100%;
    height:100px;
    align-items: center;
    place-content: center;
    justify-content: space-between;
}
.acc_grid_elm_l{
    display:flex;
    flex-direction: column;

}
.acc_grid_elm_l_t{
    display:flex;
    font-weight: bold;
    font-size: 22px;

}
.acc_grid_elm_l_b{
display: flex;
    font-weight: 600;
    font-size: 20px;
}
.acc_grid_elm_r{
    display: flex;
        font-weight: bold;
        font-size: 22px;
        text-decoration: underline;

}
.acc_grid_elm_r:hover{
    cursor: pointer;
}
.acc_grid_elm_l_b_inp{
    display: flex;
    width:220px;
    height:30px;
    margin-top: 20px;
    font-size: 20px;

}
.acc_btn{
    display:flex;
    border-radius: 10px;
    border:1px solid black;
    align-items: center;
    place-content: center;
    margin-top: 20px;
}
.acc_btn:hover {
cursor: pointer;
}

@media only screen and (max-width: 992px) {
    .acc_grid{
        display:flex;
        width:90%;
        margin-left:auto;
        margin-right: auto;
        margin-bottom: 50px;
    }

}
