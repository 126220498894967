.reset {
  background-color: white;
  height: 100vh;
  display: flex;
  flex: 0.25;
  place-items: center;
  margin-left: auto;
  margin-right: auto;

  flex-direction: column;
}
.reset__container {
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 20px;
}
.reset__container > h1 {
  font-weight: 500;
  margin-bottom: 20px;
}
.reset__forgotpasswordbutton {
  background-color: #81dfe1;
  border: 1px solid;
  height: 30px;
  width: 100%;
  margin-top: 10px;
  border-color: #366162 #3b6465 #436c6d;
  color: #111;
  border-radius: 2px;
}
.header__logo {
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  width: 100px;
  height: 100px;
  margin-right: auto;
  margin-left: auto;
}
