.contact{
    display: flex;
    flex-direction: column;
        width: 100%;
        min-height: 70vh;
        max-height: fit-content;
      
}
.contact_title{
    display:flex;
    width:80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 50px;
}
.contact_grid_container {
    display: grid;
    /* grid-template: 25fr/ auto auto auto auto ; */
    /* grid-template-rows: 25fr 25fr 25fr 25fr ; */
    /* grid-auto-columns: 25fr; */
    grid-template-columns: repeat(4, 23%);
    gap: 20px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    /* border:1px solid black; */
    width: 80%;
    
}

.contact_block {
    display: flex;
    flex-direction: column;
    /* max-width: 300px; */
    /* width:25fr; */
    /* min-height: 200px; */
    height: 120px;
    padding:10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    color: black;
    transition: transform .2s;
    align-items: center;
    place-content: center;
    font-size: 22px;
    font-weight: 700;
    background-color: white;
    /* Animation */

    border: 1px solid rgb(221, 221, 221);
        border-radius: 12px;
        padding: 24px;
        box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
        background-color: white;


}
.contact_block_icon{
    display: flex;
}
.contact_block_title{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    
    justify-content: center;
}
.contact_block:hover {
    cursor: pointer;
}
.contact_faq{
    display:flex;
    flex-direction: column;
    width: 80%;
    height: fit-content;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    

}
.contact_faq_elm{
    display:flex;
    flex-direction: column;
    width:40%;
    height:fit-content;
    padding:10px;
    margin-left:auto ;
    margin-right: auto;
    border: 1px solid #ddd;
    align-items: center;
    margin-bottom: 20px;
    background-color: white;
    /* z-index: 30; */

}
.contact_faq_elm:hover {
    cursor: pointer;
}

.contact_faq_title{
    display:flex;
    margin:auto auto;
    font-size: 22px;
    font-weight: 700;
    width:80%;
    justify-content: space-between;
    
}
.contact_icon{
    display:flex;
}
.contact_faq_elm_desc {
display:flex;
flex-direction: column;
width: 80%;
margin: auto auto;
    font-size: 20px;
    font-weight: 400;

}
.contact_faq_elm_desc_item{
    display:flex;
    font-size: 20px;
        font-weight: 400;
        margin-bottom: 5px;
}
@media only screen and (max-width: 1600px) {
    .contact_block {
        font-size: 22px;
    }
}
@media only screen and (max-width: 1200px) {
    .contact_block {
        font-size: 20px;
    }
}
@media only screen and (max-width: 992px) {
    .contact_block {
        font-size: 19px;
    }
    .contact_faq_elm{
        width:90%;
        
    
    }
}
@media only screen and (max-width: 768px) {
    .contact_block {
        font-size: 16px;
    }
    .contact_grid_container {
        width: 98%;
        gap: 7px;
        grid-template-columns: repeat(3, 33%);
        
    }
    .contact_faq_elm{
        width:95%;
    
    }
}
@media only screen and (max-width: 576px) {
    .contact_block {
        font-size: 14px;
    }
    .contact_grid_container {
        width: 98%;
        gap: 7px;
        grid-template-columns: repeat(2, 49%);
    }
    
}
@media only screen and (max-width: 480px) {
    .contact_block {
        font-size: 12px;
    }
    
   
}