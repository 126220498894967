.resa{
    display:flex;
    flex-direction: column;
    margin-top: 10%;
    width:70%;
    margin:auto auto;
    height:100vh;
    /* border:1px solid black; */
    
}
.resa_header{
    display:flex;
    margin-right:auto;
    align-items: center;
    /* border: 1px solid black; */
   

}
.resa_header_text{
    display:flex;
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
}
.resa_icon{
    display:flex;
}
.resa_icon:hover{
    cursor: pointer;

}
.resa_body{
    display:flex;
    width:100%;
    height:fit-content;
    justify-content: space-between;
}
.resa_body_left{
    display:flex;
    width:45%;
    flex-direction: column;
    margin-left: 8px;
    /* border:1px solid black; */
    border: 1px solid rgb(221, 221, 221);
        border-radius: 12px;
        padding: 24px;
        box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
        background-color: white;

}
.resa_body_right{
    display:flex;
    flex-direction: column;
    width:45%;
    height:fit-content;
    /* border-radius: 10px;
    border:1px solid black;
    padding:10px; */
    border: 1px solid rgb(221, 221, 221);
        border-radius: 12px;
        padding: 24px;
        box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
        background-color: white;

}
.resa_body_right_container{
    display:flex;
    flex-direction: column;
    width:100%;
    justify-content: space-between;
    font-weight: 500;
}
.resa_body_left_container{
    display:flex;
    
}
.resm_btn_container_item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5px;

}

.resm_btn_container_item_l {
    display: flex;
    font-weight: 400;

}

.resm_btn_container_item_r {
    display: flex;

}

.resm_btn_container_total {
    display: flex;
    width: 100%;
    justify-content: space-between;

}

.resm_btn_container_total_l {
    display: flex;
    font-weight: bold;

}

.resm_btn_container_total_r {
    display: flex;
    font-weight: bold;

}