.bookings{
    display:flex;
    width:100vw;
    min-height:100vh;
    max-height: fit-content;
}
.bookings_elms{
    display:flex;
    width:100%;
   height:100%;
}
.bookings_none{
    display:flex;

}
.bookings_list{
    display:flex;
    flex-direction: column;
    width:100%;
    height:100%;
}
.bookings_list_item{
    display:flex;
    min-width:50%;
    max-width: 95%;
  
    height: fit-content;
   
    margin-left:auto ;
    margin-right: auto;
    margin-top: 50px;
    border-radius: 10px;
    /* border: solid 1px #555; */
    background-color: #eed;
    box-shadow:  0 0 10px  rgba(0,0,0,0.6);
    -moz-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
    -webkit-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
    -o-box-shadow: 0 0 10px  rgba(0,0,0,0.6);

}
.bookings_list_item_1{
    display:flex;
    width:50%;
    
    height: fit-content;
    
}
.bookings_list_top{
    display:flex;
    width:100%;
    height:fit-content;
    
}
.bookings_list_top_img{
    width:100%;
    height:auto;
    border-radius: 10px 0px 0px 10px;
}
.bookings_list_top_head{
    position: absolute;
   padding:5px;
  margin-left: 10px;
  margin-top: 10px;
   border-radius: 10px;
   background-color: white;
   font-size: 12px;
   font-weight: 700;

}
.bookings_list_item_2{
    display:flex;
    flex-direction: column;
    width:50%;
    
}
.bookings_list_name{
    display:flex;
    font-weight: bold;
    height:33%;
    align-items: center;
    place-content: center;

}
.bookings_list_info{
    display:flex;
    flex-direction: column;
    height:33%;
    justify-content: center;
    font-weight: 500;
    
    justify-content: space-between;
}
.bookings_list_info_dates{
    display:flex;
    justify-content: center;
  

}
.bookings_list_info_addr{
    display:flex;
    /* justify-content: center; */
    width:60%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    /* border:1px solid black; */
    place-content: center;
    margin-top: 10px;
    
   

}
.bookings_list_button{
    display:flex;
    height:33%;
    align-items: center;
    place-content: center;
}
.bookings_list_button:hover{
   cursor: pointer;
}
.booking_details{
    display:flex;
}
@media only screen and (max-width: 1600px) {

       
}
@media only screen and (max-width: 1200px) {
 
       
}
@media only screen and (max-width: 992px) {
   
       
}
@media only screen and (max-width: 768px) {
    .bookings_list_item{
       flex-direction: column;
       height: fit-content;
    
    }
    .bookings_list_top_img{
        width:100%;
        height:auto;
        border-radius: 10px 10px 0px 0px;
    }
    .bookings_list_item_1{
        width:100%;
    }
    .bookings_list_item_2{
        width:100%;
        height: fit-content;
       
    }
    
    .bookings_list_button{
       margin-top: 25px;
       
    }
       
}
@media only screen and (max-width: 576px) {
   
    
 
       
}
@media only screen and (max-width: 480px) {
   
       
}