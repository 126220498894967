.calender_container{
    display:flex;
    flex-direction: column;
    width:90%;
    height:fit-content;
    margin-left:auto;
    margin-right:auto;
    margin-top: 50px;
    /* margin-top: auto;
    margin-bottom: auto; */
    /* border:1px solid black; */
    /* font-size: 22px; */
    border: 1px solid rgb(221, 221, 221);
        border-radius: 12px;
        padding: 24px;
        box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
        background-color: white;
}


.calender{
    display:flex;
    flex-direction: column;
    width:100%;
    height:fit-content;
   
    /* border:1px solid red; */
}
.calender_top{
    display:flex;
    margin-bottom: auto;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(221, 221, 221);
    
    width: 100%;
}
.calender_top_month{
    font-size: 15px;
    font-weight: bold;
    margin-right: 20%;
    margin-left: 20%;
   /* border:1px solid black; */

}
.calender_top_arrow:hover{
    cursor:pointer;

}
.calender_bodyContainer{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    /* border: 1px solid black;
    padding:20px; */
}
.calender_bodyContainer_elm {
    display: flex;
    flex-direction: column;
    width:45%;
    
    
    
}
/* @media only screen and (max-width: 1300px) {
    .calender_bodyContainer {
flex-direction: column;
    }

} */
.calender_body{
    display: grid;
    width:100%;
    /* grid-auto-flow: column;
    grid-auto-columns: 50px;
    grid-row-end: span 7; */
    grid-template: 50px/ auto auto auto auto auto auto auto;
    grid-template-rows: 50px 50px 50px 50px 50px 50px;
    gap: 10px;
    padding: 10px;
   height:100%;
    
    /* height:100%;
    width:100%; */
    /* border: 1px solid black; */
    
}
.calender_body_element{
   display:flex;
    width:50px;
    height:50px;
    border:3px solid white;
    align-items: center;
    place-content: center;
    border-radius: 10px;
    /* border: 1px solid black;
    margin-left: auto;
    margin-right: auto; */
  
}

/* @media only screen and (max-width: 1300px) {

} */
.calender_body_element_avai{
    /* background-color: green; */
    font-weight: bold;

}
.calender_body_element_notAvai{
    /* background-color: red; */
    text-decoration: line-through;
    opacity: 0.2;
    
}

.calender_body_element_avai:hover{
cursor: pointer;
border-color: black;
}
.calender_body_element_imp{
    font-weight: bold;
    border-color:#a6a382;
    opacity: 1 !important;

}
.calender_body_element_imp:hover {
    cursor: pointer;
    border-color: black;
}
.calender_selected{
    background-color: black !important;
    color:white !important;
    opacity: 0.69;
}
.calender_sat{
    background-color: #D3D3D3;
    /* opacity:0.2; */
}
.calender_not_sat{
    opacity:0.6;
}
.calender_onlyLeave{
    color:black ;
    font-weight: 800;
    opacity:0.5;
    text-decoration: line-through;
}
.calender_onlyLeave:hover{
    
}
.cal2_inac{
    display:none;
}
@media only screen and (max-width: 1600px) {
   
}
@media only screen and (max-width: 1200px) {
    /* .calender_bodyContainer {
        flex-direction: column;
    } */
    .calender_body_element {
        width: 35px;
        height: 35px;
        font-size: 18px;
        
    }
    .calender_body {
        grid-template: 35px/ auto auto auto auto auto auto auto;
        grid-template-rows: 35px 35px 35px 35px 35px 35px;
    }
}
@media only screen and (max-width: 992px) {
    /* .calender_bodyContainer {
        flex-direction: column;
    } */
    .calender_body_element {
        width: 35px;
        height: 35px;
        font-size: 18px;
        
    }
    .calender_body {
        grid-template: 35px/ auto auto auto auto auto auto auto;
        grid-template-rows: 35px 35px 35px 35px 35px 35px;
    }
}
@media only screen and (max-width: 768px) {
    /* .calender_bodyContainer {
        flex-direction: column;
    } */
    .calender_body_element {
        width: 26px;
        height: 26px;
        font-size: 16px;
        
    }
    .calender_container{
       
        width:100%;
       
    }
    .calender_body {
        grid-template: 26px/ auto auto auto auto auto auto auto;
        grid-template-rows: 26px 26px 26px 26px 26px 26px;
    }
}
@media only screen and (max-width: 576px) {
    .calender_bodyContainer {
        flex-direction: column;
    }
    .calender_body_element {
        width: 35px;
        height: 35px;
        font-size: 16px;
        
    }
    .calender_body {
        grid-template: 35px/ auto auto auto auto auto auto auto;
        grid-template-rows: 35px 35px 35px 35px 35px 35px;
    }
}
@media only screen and (max-width: 480px) {
    .calender_bodyContainer {
        flex-direction: column;
    }
    .calender_container{
margin-top: 20px;
    }
    .calender_body_element {
        width: 30px;
        height: 30px;
        font-size: 16px;
        
    }
    .calender_body {
        grid-template: 30px/ auto auto auto auto auto auto auto;
        grid-template-rows: 30px 30px 30px 30px 30px 30px;
    }
}
@media only screen and (max-width: 320px) {
    .calender_bodyContainer {
        flex-direction: column;
    }
    .calender_body_element {
        width: 25px;
        height: 25px;
        font-size: 15px;
        
    }
    .calender_body {
        grid-template: 25px/ auto auto auto auto auto auto auto;
        grid-template-rows: 25px 25px 25px 25px 25px 25px;
    }
}