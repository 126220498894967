
/* @import url(//db.onlinewebfonts.com/c/5ebe3f5f5a6cdaffeafd16be0c93df0b?family=Garet+Book); */
.app{
  display:flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100vw;
  /* max-width: fit-content; */
  height:100%;
 
  /* -ms-overflow-style: none;
    scrollbar-width: none;
  
    overflow-y: scroll; */
    /* font-family:SVN-Miller Banner; */
    font-family: sans-serif;
   
  
}

body::-webkit-scrollbar {
  display: none;
}
html{
  width:100vw;
}
/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  width:100%;
}
.app_content{
  display:flex;
  margin-top: 130px;
  width: 100%;
 
 
 
}
@media only screen and (max-width: 1500px) {
  .app {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .app_content{
   
    margin-top: 80px;
   
  }
  
}

* {
  margin: 0;
}
.app_icon:hover{
  cursor: pointer;
}
:root {
  --main-bg-color: #F5F5DC;
  --second-bg-color: #D6E8C6;
}
.app::-webkit-scrollbar {
  display: none;
}

.navbar_bg {
  position: absolute;
  right: 0;
  top: 0;
  width: 60%;
  height: 100vh;
  background-color: var(--second-bg-color);
  z-index: -20;
}


