.fac{
    display:flex;
    flex-direction: column;
    width:100%;
    height:100%;
    /* background-color: var(--main-bg-color); */
    /* z-index: 20; */
}
.fac_header{
    display:flex;
    margin-left: 20px;
}
.fac_con{
    display:flex;
    width:70%;
    margin-right: auto;
    height:fit-content;
    padding:40px;
    font-size: 20px;
    background-color: var(--main-bg-color);
    

}
.fac_con_tit {
    display: flex;
    width:30%;
    margin-left: 20px;
    font-weight: bold;
    font-size: 25px;
    


}

.fac_con_table {
    display: grid;
    grid-template-columns: repeat(2, 330px);
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    height:fit-content;
    
}
.fac_icon{
    color:green;
    margin-right: 5px;
}

.fac_con_table_elm {
    display: flex;
    align-items: center;
    height: 20px;
    width:100%;
    justify-content: left;
    color: black;
    transition: transform .2s;
   
    


}
@media only screen and (max-width: 1600px) {
    .fac_con_table {
        width: 70%;
    }
       
}
@media only screen and (max-width: 1200px) {
    .fac_con{
        width:80%;
    
    }
    
    .fac_con_table {
        width: 80%;
        font-size: 18px;
    }
    .fac_con_tit {
        width:20%;
        margin-left: 10px;
        font-size: 22px;
    
    
    }
    .fac_con_table {
        /* width: 90%; */
        
        grid-template-columns: repeat(2, 300px);
    }
       
}
@media only screen and (max-width: 992px) {
    .fac_con{
        width:95%;
    
    }
    
    .fac_con_table {
        width: 80%;
        font-size: 14px;

    }
    .fac_con_tit {
        width:20%;
        /* margin-left: 5px; */
        font-size: 16px;
        /* border: 1px solid black;
     */
    
    }
       
}
@media only screen and (max-width: 768px) {
    .fac_con{
        width:100%;
        padding:20px;
        flex-direction: column;
    
    }
    
    .fac_con_table {
        width: 90%;
        font-size: 14px;
        grid-template-columns: repeat(2, 180px);
    }
    .fac_con_tit {
        width:fit-content;
       
        font-size: 12px;
       margin-bottom: 5px;
       
    
    }
       
}
@media only screen and (max-width: 576px) {
   
    
    .fac_con{
        flex-direction: column;
    
    }
    
    .fac_con_table {

        font-size: 14px;
        grid-template-columns: repeat(2, 180px);
    }
    .fac_con_tit {
        width:fit-content;
       
        font-size: 12px;
       margin-bottom: 5px;
    
    }
       
}
@media only screen and (max-width: 480px) {
    .fac_con{
        flex-direction: column;
    
    }
    
    .fac_con_table {

        font-size: 10px;
        grid-template-columns: repeat(2, 160px);
    }
    .fac_con_tit {
        width:fit-content;
       
        font-size: 14px;
       margin-bottom: 5px;
    
    }
       
}