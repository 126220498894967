.cookie{
    display:flex;
    width:100%;
    min-height: 100vh;
    max-height:fit-content
}
.cookie_con{
    display:flex;
    flex-direction: column;
    width:70%;
    height:fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    margin-top: 50px;

}
.cookie_con_h{
    display:flex;
    width:85%;
    margin-left:auto ;
    margin-right: auto;

}
.cookie_con{
    display:flex;

}
.cookie_elm{
    display:flex;
    flex-direction: column;
    width:85%;
    height:fit-content;
    padding:10px;
    margin-left:auto ;
    margin-right: auto;
    margin-top: 20px;
    border: 1px solid #ddd;
    align-items: center;
    margin-bottom: 20px;
    background-color: white;
    /* z-index: 30; */

}
.cookie_elm_show{
border: 3px solid var(--main-bg-color);
}
.cookie_title{
    display:flex;
    margin:auto auto;
    font-size: 22px;
    font-weight: 700;
    width:80%;
    justify-content: space-between;
    
}
.cookie_icon{
    display:flex;
}
.cookie_elm_desc {
    display:flex;
    flex-direction: column;
    width: 95%;
    margin: auto auto;
        font-size: 17px;
        font-weight: 400;
   
}
.cookie_elm_desc_h{
    display:flex;
}
.cookie_elm_desc_item{
    display:flex;
    font-size: 20px;
        font-weight: 400;
        margin-bottom: 5px;
}
.cookie_elm_desc_item_row{
    display:flex;
    width:100%;
    border-top: 1px solid #ddd;
    /* border-bottom: 1px solid #ddd; */
    justify-content: space-between;

}
.cookie_elm_desc_item_row_elm{
    display:flex;
    width:19%;
    /* border-right:1px solid #ddd; */
}
.cookie_elm_desc_item_row_elm_desc{
    display:flex;
    width:40%; 
    
}
@media only screen and (max-width: 1200px) {
    .cookie_con{
        width:85%;
    
    }
    .cookie_elm{
       
        width:95%;
        
    
    }
    .cookie_con_h{
        width:95%;
    
    }
    .cookie_elm_desc {
        width:95%;
        font-size: 15px;
          
    }
}
@media only screen and (max-width: 992px) {
    .cookie_con{
        width:90%;
    
    }
   
    .cookie_elm_desc {
        width:95%;
        font-size: 15px;
          
    }
}
@media only screen and (max-width: 768px) {
    .cookie_con{
        width:90%;
    
    }
}
@media only screen and (max-width: 576px) {
    .cookie_con{
        width:100%;
    
    }
}
@media only screen and (max-width: 480px) {
    .cookie_con{
        width:100%;
    
    }
    .cookie_elm_desc_item_row_elm_name{
        font-size: 8px;
    }
    .cookie_elm_desc_item_row_elm{
        font-size: 8px;
    }
    .cookie_elm_desc_item_row_elm_desc{
        font-size: 8px;

    }
}


