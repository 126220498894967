.login {
  height: 100vh;
  display: flex;
  place-items: center;
  margin-left: auto;
  margin-right: auto;

}
.login__container {
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 20px;
  background-color: white;
}
.login__container > h1 {
  font-weight: 500;
  margin-bottom: 20px;
}
.login__container > form > h5 {
  margin-bottom: 5px;
}
.login__container > form > input {
  height: 30px;
  margin-bottom: 10px;
  background-color: white;
  width: 98%;
}
.login__container > p {
  margin-top: 15px;
  font-size: 12px;
}
.header__logo {
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  width: 100px;
  height: 100px;
  margin-right: auto;
  margin-left: auto;
}
.login__button {
  background-color: #81dfe1;
  /* border: 1px solid; */
  height: 30px;
  width: 100%;
  margin-top: 10px;
  /* border-color: #366162 #3b6465 #436c6d;
  color: #111; */
  border-radius: 5px;
font-weight: 500;
  background-color: #eed;
  box-shadow:  0 0 10px  rgba(0,0,0,0.6);
  -moz-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  -webkit-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  -o-box-shadow: 0 0 10px  rgba(0,0,0,0.6);

}
.login__registerButton {
  background-color: darkgrey;
  /* border: 1px solid black; */
  /* margin-top: 10px; */
  /* color: #111; */
  /* width: 100%; */
  /* border-radius: 2px; */
}
.login__forgotpasswordbutton {
  background-color: #81dfe1;
  /* border: 1px solid; */
  /* height: 30px;
  width: 100%;
  margin-top: 10px; */
  /* border-color: #366162 #3b6465 #436c6d;
  color: #111;
  border-radius: 2px; */
}

.login__popup {
  display: flex;
  margin-top: 10px;
  font-size: 10px;
  font-weight: bold;
  background-color: black;
  color: var(--twitter-color);
  padding: 10px;
  border-radius: 20px;
  border: 1px solid white;
}
