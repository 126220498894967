.resm{
    display:flex;
    flex-direction: column;
    width:100%;
    margin:auto auto;
    height:100%;
    /* border:2px solid black; */
    margin-bottom: 50px;
    /* z-index: 20; */
   
   
}

.resm_info{
    display:flex;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 90%;
    
    margin-left: auto;
    margin-right: auto;
}
.resm_bottom{
    display:flex;
    width:100%;
    height:fit-content;
    justify-content: space-between;
    
    
}

.resm_gasten{
    display:flex;
    flex-direction: column;
    margin-left: auto;
    margin-right:auto ;
    margin-top: 20px;
    /* border:1px solid black; */
    width:30%;
}
.resm_gasten_element{
    display:flex;
    justify-content: space-between;
    /* border:1px solid black; */
    align-items: center;
    margin-top: 5px;
   
    
}
.resm_gasten_element_l{
    display:flex;
    flex-direction: column;
    

}
.resm_gasten_element_l_top{
    display:flex;
    font-size: 18px;
    font-weight: bold;

}
.resm_gasten_element_l_bottom{
    font-size: 16px;
    font-weight: 400;
    width:100%;
   
    
    

}
.resm_gasten_element_r{
    display:flex;
    
    width:100px;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-weight: bold;

}
.resm_btn_container{
    display:flex;
    flex-direction: column;
    align-items: center;
    /* border:1px solid #ddd; */
    width:fit-content;
    /* padding:10px; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    border: 1px solid rgb(221, 221, 221);
        border-radius: 12px;
        padding: 24px;
        box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
        background-color: white;

}
.resm_btn_container_items{
    display:flex;
    flex-direction: column;
}
.resm_btn_container_item{
    display:flex;
    width:100%;
    justify-content: space-between;
    margin-bottom: 5px;

}
.resm_btn_container_item_l{
    display:flex;
    font-weight: 400;
    margin-right: 10px;

}
.resm_btn_container_item_r{
    display:flex;

}
.resm_btn_container_total{
    display: flex;
        width: 100%;
        justify-content: space-between;

}
.resm_btn_container_total_l{
    display: flex;
        font-weight: bold;

}
.resm_btn_container_total_r {
    display: flex;
        font-weight: bold;

}
.resm_btn{
    display:flex;
    margin-left:auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding:20px;
    border-radius: 20px;
    background-color: grey;
    font-size: 15px;
    font-weight: bold;
}
.resm_btn_active{
    background-color: aqua ;

}

.resm_btn_active:hover{
cursor: pointer;
}
.resm_btn_textBelow{
    display:flex;
    margin:auto auto;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 500;
}
.resm_icon{
    
    
    
}

.res_icon_t{
color:green
}
.res_icon_f{
color:red
}
@media only screen and (max-width: 1200px) {
        
         .resm_gasten {

            margin-left: 10px;
           

        }
        .resm_gasten_element {
            
            
         
           

        }

}
@media only screen and (max-width: 992px) {
        
    .resm_gasten {

       margin-left: 10px;
      

   }
   .resm_gasten_element {
   }
   .resm_gasten_element_l_top{
    font-size: 16px;
   }
   .resm_gasten_element_r{
    font-size: 14px;
   }

}
@media only screen and (max-width: 768px) {
        
    .resm_gasten {

       margin-left: 10px;
      

   }
   .resm_info{
   
    width: 100%;
    
   
}
   .resm_gasten_element_l_top{
    font-size: 14px;
   }
   .resm_gasten_element_r{
    font-size: 12px;
   }
   .resm_btn_container{
    font-size: 14px;
   }

}
@media only screen and (max-width: 576px) {
    .resm_bottom {
        flex-direction: column;
       
    }  
    .resm_gasten {
       
       width: 60%;
       
       margin-left: auto;
       margin-right: auto;
      

   }
   
   .resm_gasten_element_l_top{
    font-size: 18px;
   }
   .resm_gasten_element_r{
    font-size: 16px;
   }
   

}
@media only screen and (max-width: 480px) {
    .resm{
       
        width:98%;
    
    }
    .resm_gasten {
        width: 80%;
    }
   

}



/* @media only screen and (max-width: 768px) {
    .resm_bottom {
        flex-direction: column;
    }
     .resm_gasten {
        margin-left: auto;

    }

} */