
.omgeving_first{
    display:flex;
    flex-direction: column;
    width:100%;
    height:fit-content;

}
.omgeving_first_1 {
    display:flex;
    height: 80vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    width: 90%;
    margin-top: 10px;
    
   
   
}
.omgeving_first_1_l {
    display:flex;
    flex-direction: column;
    width: 50%;
    height: fit-content;
    font-size: 160px;
    line-height: 160px;
    font-weight: bold;
    margin-top: auto;
    
}
.omgeving_first_1_r_bottom {
    display: flex;
    flex-direction: column;
    height: fit-content;
    font-family: Dual, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    /* border:1px solid black; */
    margin-top: auto;


}
.omgeving_first_1_r_top {
    display: flex;
    height: fit-content;

    margin-top: auto;
    width: 100%;
    /* border: 1px solid black; */
}

.omgeving_first_1_r {
    display: flex;
    flex-direction: column;
    width: 50%;
        /* border: 1px solid black; */
       

}

.omgeving_first_1_r_top_img{
    width:auto;
    height: 100px;
    margin-top: auto;
    margin-right: auto;
    margin-left: 10%;
    /* border: 1px solid black; */
   
}

.omgeving_first_imgCon{
display:flex;
width:80%;
height:fit-content;
margin-left: auto;
margin-right: auto;

}
.omgeving_first_img{
    width:100%;
    height:auto;

}

@media only screen and (max-width: 1600px) {
   

    .omgeving_first_1 {

        width: 95%;
        
    }
    .omgeving_first_1_l {
        font-size: 120px;
        line-height: 120px;
        margin-top: auto;
        
    }
    .omgeving_first_1_r_top {
    
    
        margin-bottom: auto;
       
       
    }

    .omgeving_first_imgCon {
        width: 95% ;

    }

    .omgeving_first_1_r_bottom {
        font-size: 18px;
        line-height: 22px;
       
        margin-top: auto;

    }

}
@media only screen and (max-width: 1200px) {
   
    .omgeving_first_1_l {
        font-size: 100px;
        line-height: 100px;
       
        
    }
    .omgeving_first_1 {
       
        height: 70vh;
       
        
       
       
    }


    .omgeving_first_1_r_bottom {
        font-size: 15px;
        line-height: 16px;

    }
    .omgeving_first_1_r_top {
       
        height: 80px;
    
       
        /* border: 1px solid black; */
    }
    .omgeving_first_1_r_top_img{
       
        height: 50px;
       
        /* border: 1px solid black; */
       
    }

}
@media only screen and (max-width: 992px) {
    .omgeving_first_1 {

        width: 98%;
        flex-direction: column;
        align-items: center;


    }
    .omgeving_first_imgCon {
        width: 98% ;

    }
    .omgeving_first_1_r_top{
        display:none;
    }
    .omgeving_first_1_r {
       
        width: 90%;
            /* border: 1px solid black; */
           
    
    }
    .omgeving_first_1_l {
        font-size: 80px;
        line-height: 80px;
       margin-bottom: auto;

        
    }


    .omgeving_first_1_r_bottom {
        font-size: 18px;
        line-height: 22px;

    }

}
@media only screen and (max-width: 768px) {
    .omgeving_first_1_l {
        font-size: 60px;
        line-height: 60px;  
    }
    .omgeving_first_1_r_bottom {
        font-size: 13px;
        line-height: 19px;
    }
   
}
@media only screen and (max-width: 576px) {
    .omgeving_first_1_l {
        font-size: 50px;
        line-height: 50px;  
    }
    .omgeving_first_1_r_bottom {
        font-size: 12px;
        line-height: 18px;
    }
    
}
@media only screen and (max-width: 480px) {
    /* .omgeving_first_1_l {
        font-size: 35px;
        line-height: 35px;  
    } */
    
}