.privacy_elm_desc_text{
    display:flex;
    flex-direction: column;
}
.privacy_elm_desc_text_tit{
    display:flex;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
}
.privacy_elm_desc_text_desc{
    display:flex;
}