.bb{
    display:flex;
    flex-direction: column;
    width:100%;
    height:300px;
    /* margin-top: auto; */
    margin-bottom: auto;
    /* background-color: #a6a382; */
    background-color: var(--second-bg-color);
    /* margin-top:50px; */
    padding:20px;
  
   
    
    
}
.bb_row{
    display:flex;
    width:100%;
    height:fit-content;
    /* border:1px solid #ddd; */
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
   
    
}
.bb_row_bottom {
    display: flex;
    width: 100%;
    height: fit-content;
   
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    
   
}
.bb_col{
    display:flex;
    width:33%;
    
    height:100%;
    flex-direction: column;
    
    align-items: center;
   

}
.bb_row_elm_bottom{
    display:flex;
    width:33%;
    height:100%;
    flex-direction: column;
    align-items: center;
   

}
.bb_row_elm_bottom:hover{
   cursor: pointer;
   

}
.bb_col_tit{
    display:flex;
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
}
.bb_col_elm {
    display: flex;
    font-size: 15px;
    font-weight: 400;
    color:black;
}
a{
    text-decoration: none;
}
@media only screen and (max-width: 992px) {
    .bb_row{
       
        width:100%;
       
    }
    .bb_row_bottom {
        width:100%;
       
       
    }
}
@media only screen and (max-width: 768px) {
    .bb_row{
       
       
       
    }
    .bb_row_bottom {
        
       
       
    }
}
@media only screen and (max-width: 576px) {
    .bb_row{
       
      
       
       
    }
    .bb_col{
        font-size: 14px;
    }
    .bb_row_bottom {
       
       
       
    }
    .bb_col_tit{
        font-size: 14px;
        
    }
    .bb_col_elm {
        font-size: 12px;

    }
}
@media only screen and (max-width: 480px) {
    .bb_row{
       
       
       
    }
    .bb_row_bottom {
       
       
    }
    .bb_col_tit{
        font-size: 14px;
        
    }
    .bb_col_elm {
        font-size: 12px;

    }
}