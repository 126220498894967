/* @import url(//db.onlinewebfonts.com/c/345a4b9dc453432314209d38245e4bff?family=SVN-Miller+Banner);
@import url(//db.onlinewebfonts.com/c/d979624d1cb77d6523f9b3adc2bcef31?family=RotisSansSerif);
@import url(//db.onlinewebfonts.com/c/85ae188642d05e631919cb350d543e36?family=Serifa); */
/* @import url(//db.onlinewebfonts.com/c/5ebe3f5f5a6cdaffeafd16be0c93df0b?family=Garet+Book); */
.navbar {
    display: flex;
    height: 130px;
    width: 100%;
    position: fixed;
    z-index: 10;
    /* align-items: center; */
    /* justify-content: space-between; */
    border-bottom:1px solid white; 
    /* font-family: Garet Book; */
    background: linear-gradient(
        to right,
        white 0%,
        white 40%,
        var(--second-bg-color) 40%,
        var(--second-bg-color) 100%
      );
      
}
.navbar_trans {
   background: none !important;
   border-bottom: none !important;
    
}
.navbar_brand {
    display: flex;
    width: 15%;
    align-items: center left;
    height:100%;
   
    
   
}
.navbar_item_img {
    display: flex;
    max-height: 100%;
    width: auto;
    margin-left: 20px;
}

.navbar_menu {
    display: flex;
    width: 55%;
    align-items: center;
    justify-content: space-between;
   
    margin-left: auto;
    margin-right: auto;
    font-weight: 200;
    
}
.navbar_menu_item{
    display:flex;
}

.navbar_menu3 {
    position: absolute;
    top: 130px;
    left: 75%;
    display: flex;
    flex-direction: column;
    width: 25%;
    height: fit-content;
    background-color: white;
    z-index: 100;

}

.dropbtn {
display:flex;
    font-weight: 600;

    font-size: 18px;
    color: black;
    
    width:fit-content;
    margin-left: 5px;
    margin-right: 5px;

}
.navbar_menu2 {
    position: absolute;
    top: 80px;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100vh;
    background-color: white;
    z-index: 100;
    

}
.dropbtn2 {
    display:flex;
    font-weight: 500;
    
    font-size: 14px;
    color: black;
        
    width:100%;
    /* margin-left: 10px;
    margin-right: 5px; */
    padding:10px;
    border-top: 1px solid #ddd;
    
}
.dropbtn2:hover {
    color: white;
background-color: #3D3D3D;
}
.dropbtn2_menu{
    display:flex;
    font-weight: 700;
    
    font-size: 16px;
    color: black;
        
    width:100%;
    margin-left: 10px;
    margin-right: 5px;
    margin-bottom: 10px;
    

}
.navbar_user {
    display: flex;
    width: 10%;
    max-width: fit-content;
    align-items: center;
    place-content: center;
   
    margin-right: 5px;
   
}
.navbar_flags{
    display: flex;
    flex-direction: column;
    width: 8%;
    height:100%;
    align-items: center;
    place-content: center;
    /* border: 1px solid black; */
   
    

}

.navbar_flags_item:hover{
cursor: pointer;
}
.navbar_flags_item_com{
    display: flex;
    width: 100%;
    height:100%;
    
}
.navbar_flags_item{
    display: flex;
    max-width: 50px;
    max-height: 50px;
    /* border: 1px solid black; */
    margin-top: 5px;
    

}
@media only screen and (max-width: 1600px) {
    .dropbtn {

        font-weight: bold;
        font-size: 16px;
        color: black;
    }
}
@media only screen and (max-width: 1200px) {
    .dropbtn {

        font-weight: bold;
        font-size: 14px;
        color: black;
    }
}

@media only screen and (max-width: 992px) {
    .dropbtn {

        font-weight: bold;
        font-size: 14px;
        color: black;
    }
    .navbar_brand {
       
        width: 14%;
        height:100%;
        /* border: 1px solid black; */
       
    }
    .navbar_item_img {
        
       
        max-width: 100%;
        margin-left: 6px;
    }
    .navbar_flags_item{
        display: flex;
        max-width: 40px;
        max-height: 40px;
        /* border: 1px solid black; */
        margin-top: 5px;
    
    }
    .navbar_menu {
        display: flex;
        width: 65%;
        align-items: center;
        justify-content: space-between;
        /* border:1px solid black; */
        margin-left: auto;
        
        
    }

}
@media only screen and (max-width: 768px) {
    .dropbtn {

        font-weight: bold;
        font-size: 13px;
        color: black;
        margin-top: 5px;
    }
    .navbar {
        height:80px;

    }
    .navbar_menu3 {
       
        top: 80px;
       
    
    }
    .navbar_trans {
        height:80px;

    }
    .navbar_menu {
        display: none;
        font-size: 13px;
        
    }
    .navbar_menu_small{
        display:flex;
        width: 30%;
        align-items: center;
       
        place-content: center;
        height:100%;
    }
    .navbar_small{
        display: flex;
        color:black;
       height:100%;
        align-items: center;
       
        font-size: large;

    }

    

}





.dropbtn:hover{
    cursor: pointer;

}

.dropdown {
    position: relative;
    /* display: inline-block; */
    
}
button{
border:none;
background-color: none;
background: none;
box-sizing: none;
}

.dropdown-content {
    display: none;
    position: absolute;
   margin-right: 50px;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    font-weight: 600;
}

.dropdown-content a {
    color: black;
    padding: 10px 14px;
    text-decoration: none;
    display: block;
    font-size: 14px;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}



#americanFlag {
    width: 5%;
}

.navHover:hover {
    color: orange !important;
   
}

.navbar_search {
    display: flex;
    width: 200px;
}

