.huis{
    display:flex;
    flex-direction: column;
    width:100vw;
    height:fit-content;
    /* z-index: 20; */
    margin-top: 130px;
   
    

}
.huisje_scroll_link:hover{
    cursor: pointer;
    
}
.huisje_scroll_link {
    color:orange

}
.huis_first{
    display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        
       
       

}
.huis_first_1 {
    display: flex;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    margin-top: 50px;
    width: 60%;
}
.huis_first_1_l{
    display: flex;
        width: 40%;
       
        margin-bottom: auto;

}
.huis_first_1_r {
    display: flex;
    flex-direction: column;
    width: 60%;
   

}
.huis_first_1_r_bottom {
    display: flex;
    flex-direction: column;
    height: fit-content;
    font-family: Dual, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
   
    margin-top: auto;
    margin-bottom: auto;

}
.huis_l{
    display:flex;
    
    width:30%;
    /* border:1px solid black; */

}
.huis_l_con{
    display: flex;
        flex-direction: column;
        width:100%;
        height: 70%;
            margin-top: auto;
            margin-bottom: auto;
            /* border:1px solid black; */

}
.huis_l_elm{
    display:flex;
    width:60%;
    height:50px;
    /* margin-left: auto; */
    margin-right: auto;
    /* border: 1px solid black; */
    font-size: 40px ;
    
}
.huis_l_img{
    display: flex;
    width:20%;
    margin-top: auto;
    margin-bottom: auto;

}
.huis_l_text{
display:flex;
width:80%;
font-size: 20px;
font-weight: 700;
margin-top: auto;
    margin-bottom: auto;
}
.huis_r{
    display:flex;
    width: 70%;
    background-color: #F5F5DC;

}

.huis_r_text{
    display:flex;
    width:50%;
    height:70%;
   margin-top: auto;
   margin-bottom: auto;
   margin-left: 5%;
    /* font-size: 20px; */
    font-weight: 400;
    letter-spacing: 2px;
    /* border:1px solid black; */
    text-align: justify;
}

@media only screen and (max-width: 1200px) {
.huis_r_text {
height: 90%;
}
.huis_l_con {
    height: 90%;

}

}
@media only screen and (max-width: 992px) {
    .huis_first_1 {
       flex-direction: column;
        margin-top: 5px;
    }
    .huis_first_1_l{
            width: 100%;
    }
    .huis_first_1_r {
        width: 100%;
    }

    .huis_l_con{
        display: grid;
        grid-template-columns: repeat(4, 23%);
        gap: 20px;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
        /* border:1px solid black; */
        width: 100%;
    
    }
    .huis_l_elm{
        display:flex;
        width:100%;
        height:50px;
        margin-left: auto;
        margin-right: auto;
        /* border: 1px solid black; */
        font-size: 40px ;


        
    }

    

}
@media only screen and (max-width: 768px) {
    .huis_l_con{
        grid-template-columns: repeat(3, 32%);
        gap: 10px;
    }
    .huis{
       
        margin-top: 80px;
        font-size: 10px;
        
    
    }
    .huis_first_1 {
       
        width:100%;
         
         /* border: 1px solid black; */
     }
    
}
@media only screen and (max-width: 576px) {
   
    .huis{
        
        width:576px;
    }
    .huis_l_con{
        grid-template-columns: repeat(2, 48%);
        gap: 10px;
    }
    /* .huis_l_img{
        font-size: small !important;
    } */
}
@media only screen and (max-width: 480px) {
    
   
}

.huis_r_imgCon {
    display: flex;
    /* border:1px solid black; */
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    width: 40%;
    margin-left: 30px;
    margin-right: auto;


}

.huis_r_img {
    display: flex;
    width: 100%;
    height: auto;

}

.huis_elm{
   display:flex;
   height:60vh;
   width:100%;
 justify-content: center;
 margin-bottom: auto;
 margin-top: 30vh; 
/* background: linear-gradient(90deg,#F5F5DC 85%,white 15%); */
background: linear-gradient(90deg, #D4CFE3 85%, white 15%);
}
.huis_elm_rev{
    background: linear-gradient(to left, #F5F5DC 85%, white 15%) !important;

}


.huis_elm_l {
    display: flex;
    flex-direction: column;
    width:30%;
    height:80%;
    margin-top: 20px;
    
}

.huis_elm_l_img_con{
    display:flex;
    width:100%;
    height:90%;
   
    

}
.huis_elm_l_img{
    display:flex;
    width:100%;
    height:100%;
   
}
.huis_elm_l_buttons{
    display:flex;
    width: 100%;
    height:10%;
    /* border: 1px solid black; */
    justify-content: center;
}
.huis_elm_r {
    display: flex;
        flex-direction: column;
        width: 30%;
        height: 50%;
        margin-left: 30px;
       
        margin-top: 20px;
        
}
.huis_elm_r_rev {
   
    margin-right: 30px;
   

}
.huis_elm_r_tit{
    display: flex;
    width: 100%;
    height: 20%;
    margin-top: 30px;
    font-size: 25px;
    font-weight: bold;

}
.huis_elm_r_desc{
    display: flex;
    width: 100%;
    height: 80%;
    font-size: 20px;
    font-weight: 400;
    text-align: justify;
}
.huis_icon{
    color:grey;
}
.huis_icon:hover{
    cursor: pointer;
}

.huisje_first_imgCon{
    display:flex;
    width:60%;
    /* border: 1px solid black; */
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;

}
.huisje_first_imgCon_l {
    display:flex;
    width:45%;
    height:fit-content;

}
.huisje_first_imgCon_l_img {
    display:flex;
    width:100%;
    height:auto;

}
.huisje_first_imgCon_r {
    display: flex;
        width: 45%;
        height: fit-content;

}
.huisje_first_imgCon_r_img {
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 20%;

}
@media only screen and (max-width: 1600px) {
    .huis_l_text{ 
        font-size: 20px;
    }
   
       
}
@media only screen and (max-width: 1200px) {
    .huis_l_text{ 
        font-size: 18px;
    }
    .huis_first_1 {
        margin-bottom: 50px;
        margin-top: 50px;
        width: 70%;
    }
}
@media only screen and (max-width: 992px) {
    .huis_l_text{ 
        font-size: 16px;
    }
    .huis_first_1 {
        margin-bottom: 50px;
        margin-top: 50px;
        width: 80%;
        
    }
    .huis_l_img{
      
        width:15%;
       margin-right: 15px;
    
    }
    .huis_first_1_r_bottom {  
        font-size: 18px;
        line-height: 30px;
    }
    
}
@media only screen and (max-width: 768px) {
    .huis_l_text{ 
        font-size: 16px;
    }
    .huis_first_1 {
        margin-bottom: 50px;
        margin-top: 50px;
        width: 90%;
    }
    .huis_first_1_r_bottom {  
        font-size: 18px;
        line-height: 30px;
    }
}
@media only screen and (max-width: 576px) {
    .huis_l_text{ 
        font-size: 14px;
    }
   
}
@media only screen and (max-width: 480px) {
    .huis_l_text{ 
        font-size: 12px;
        margin-left: 5px;
    }
    .huis_l_img{
      
        width:10%;
       margin-right: 20px;
    
    }
    .huis_first_1_r_bottom {  
        font-size: 16px;
        line-height: 28px;
    }
}