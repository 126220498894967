.recc{
    display:flex;
    flex-direction: column;
    margin: auto auto auto auto;
    width:50%;
    height:70%;
    /* border:1px solid black; */
}
.recc_header{
    display:flex;
    width:100%;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
    /* border:1px solid black; */
    margin-top: 20px;
    margin-bottom: 20px;
}
.recc_text{
    display:flex;
   width:100%;
   /* border:1px solid black; */
}
.recc_text_input{
    display:flex;
    margin-left:auto;
    margin-right:auto;
    margin-top: 10px;
    width:100%;
   
    /* border:1px solid black; */
}
.recc_body{
    display:flex;
    flex-direction: column;
    width:100%
}
.recc_body_element{
    display:flex;
    flex-direction: column;
    width:100%;
    margin-bottom: 10px;
    /* border:1px solid black; */



}
.recc_body_element_header{
    display:flex;
    font-size: 15px;
    font-weight: 600;
    margin-right: auto;
    margin-bottom: 10px;
    
}
.recc_body_element_input{
    display:flex;
    margin-right: auto;
    
}
.recc_submit_contaier{
    display:flex;
    justify-content: center;
    width:100%;
    margin-top: 20px;
}
.recc_submit_container_btn{
    display:flex;
    margin-left:auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding:20px;
    border-radius: 20px;
    background-color: aqua;
    font-size: 15px;
    font-weight: bold;
}
.recc_submit_container_btn:hover{
    cursor: pointer;

}