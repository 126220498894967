.resr{
    display:flex;
   
    width:100%;
    margin:auto auto;
    height:80vh;
   align-items: center;
   place-content: center;
   
   
   
}
.resr1{
    display:flex;
   
    width:fit-content;
   
    height:fit-content;
   
   font-size: 25px;
   font-weight: 700;
}
.resr1:hover{
    cursor: pointer;

}

